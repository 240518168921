@import "@/css/prepends";

.logo-loader .letter {
    fill: white;
}

#background-loader {
    background-color: #091510;
}

.page-container {
    opacity: 1;
    transition-property: opacity;
    transition-duration: .25s;
}

.page-fade-enter,
.page-fade-leave-active,
.page-fade-enter-active,
.page-fade-enter-to {
    opacity: 0;
}
