@import "@/css/prepends";

/* Style for the video background */
#video-bg {
    position: fixed;
    top: 0;
    left: 0;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -1;
    /* Ensure it's behind your other content */
    object-fit: cover;
    /* Cover the entire viewport */
}

/* Your existing styles can go here */
