@import "@/css/prepends";

.nexbtn .circle {
    transform: rotate(90deg);
    transform-origin: center;
}

/* CSS for the caret when in "up" mode */
.mode-up .nexbtn {
    transform: rotate(180deg);
}

.mode-up .nexbtn .circle {
    transform: rotate(270deg);
    transform-origin: center;
}
