@import "@/css/prepends";
@charset "UTF-8";

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";


@font-face {
    font-family: "Reservation Wide Bold";
    src: url("../fonts/Reservation-Wide-Bold.ttf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "Reservation Wide Regular";
    src: url("../fonts/Reservation-Wide-Regular.ttf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "Plantin Light";
    src: url("../fonts/Plantin-Light.ttf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "Plantin Roman";
    src: url("../fonts/Plantin-Roman.ttf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "Plantin Semibold";
    src: url("../fonts/Plantin-Semibold.otf") format("opentype");
    font-display: swap;
}

html {
    color: #222;
    font-size: 14px;
    line-height: 1.6;
    font-family: arial, helvetica, sans-serif;
}

.font1 {
    font-family: "Reservation Wide Regular", arial, helvetica, sans-serif;
}

.font2 {
    font-family: "Plantin Light", arial, helvetica, sans-serif;
}

.font2-bold {
    font-family: "Plantin Semibold", arial, helvetica, sans-serif;
}

body {
    background-color: #fff;
}

a {
    color: inherit;
}

a:hover {
    text-decoration: none;
}

/* Others */

.pagetop_btn {
    overflow: hidden;
    position: fixed;
    right: 10px;
    bottom: 110px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.8) url(../images/pagetop_ico01.svg) no-repeat 50% 50%;
    background-size: 20px auto;
    visibility: hidden;
    opacity: 0;
    text-align: left;
    text-indent: -999px;

    &.js_pagetop_btn_active {
        visibility: visible;
        opacity: 1 !important;
        transition: opacity 0.4s;
    }
}