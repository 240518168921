@import "@/css/prepends";

.menubtn .circle {
    transform: rotate(90deg);
    transform-origin: center;
}

@-webkit-keyframes rotating

/* Safari and Chrome */
    {
    from {
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes rotating {
    from {
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.menubtn .circle {
    -webkit-animation: rotating 2s linear 2s;
    -moz-animation: rotating 2s linear 2s;
    -ms-animation: rotating 2s linear 2s;
    -o-animation: rotating 2s linear 2s;
    animation: rotating 2s linear 2s;
}

.menuarrow path {
    fill: none;
    stroke: #fff;
    stroke-width: 1px;
    stroke-linecap: square;
    stroke-miterlimit: 10
}

.menu-item.active {
    opacity: 1;
}

.menu-item.active .menuarrow {
    opacity: 1;
}

.no-scroll {
    overflow: hidden;
}

.main_nav_active {
    opacity: 1;
}

.logo-header .letter {
    transition: fill 500ms ease;
    fill: white;
}

.dark-header:not(.no-scroll) .logo-header .letter {
    fill: black;
}

.menubtn circle,
.menubtn line {
    transition: fill 500ms ease;
}

.dark-header:not(.no-scroll) .menubtn circle,
.dark-header:not(.no-scroll) .menubtn line {
    stroke: black;
}

.dark-header .menu-text {
    color: black;
}

@supports (-webkit-touch-callout: none) {
    .h-screen {
        /*height: -webkit-fill-available;*/
    }
}
